import DefaultFacilityPhoto from '../assets/defaultFacility.svg'
import ProfileImage from '../assets/profileImageSquare.svg'

const getPhoto = (facility, profile, defaultPhoto) => facility || profile || defaultPhoto

export default (entity) => {
  const student = 'ST'
  const apprentice = 'AP'
  const photoUrl = entity.object_type === 'Facility'
    ? getPhoto(entity.photo_facility, entity.photo_profile, DefaultFacilityPhoto)
    : getPhoto(entity.photo_profile, ProfileImage)
  if (entity.object_type === 'Facility') {
    return {
      name: 'facility',
      type: 'FA',
      photoUrl
    }
  } else if (entity.member_type_label === apprentice) {
    return {
      name: 'associate',
      type: 'AP',
      photoUrl
    }
  } else if (entity.member_type_label === student) {
    return {
      name: 'student',
      type: 'ST',
      photoUrl
    }
  }
  return {
    name: 'member',
    type: 'MB',
    photoUrl
  }
}

export const getClassificationFromFacility = entity => ({
  name: 'member',
  type: entity.member_type,
  photoUrl: entity.photo_profile
})
