import React, { Fragment } from 'react'
import { RefinementList } from 'react-instantsearch/dom'
import FaAngleRight from 'react-icons/lib/fa/angle-right'
import FaAngleUp from 'react-icons/lib/fa/angle-up'

import refinerConfig from '../../utils/RefinerConfig'
import RefineBy from './RefineBy'
import LocationFilter from './LocationFilter'
import { FilterResults, CloseIcon, Container, CloseWrapper, HideFilters } from './styled'

export class Refiners extends React.Component {
  constructor (props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      expanded: false
    }
  }

  toggle () {
    this.state.expanded && window.scrollTo({ top: 0, behavior: 'smooth' })
    this.setState({
      expanded: !this.state.expanded
    })
  }

  render () {
    const { expanded } = this.state
    const { searchState } = this.props

    return (
      <Fragment>
        <FilterResults onClick={this.toggle} expanded={expanded}>Filter Results<FaAngleRight /></FilterResults>
        <Container expanded={expanded}>
          {expanded && <CloseWrapper><CloseIcon onClick={this.toggle} size='2em' /></CloseWrapper>}
          <LocationFilter {...this.props} />
          {refinerConfig(searchState).map(attr => (
            <RefineBy {...{ text: attr.label, key: attr.label }}>
              <RefinementList {...attr} />
            </RefineBy>
          ))}
          <HideFilters onClick={this.toggle}>Hide Filters<FaAngleUp /></HideFilters>
        </Container>
      </Fragment>
    )
  }
}

export default Refiners
