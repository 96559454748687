import { sortBy, prop, curry, path } from 'ramda'

const typeLabels = {
  MB: 'Member',
  AP: 'Associate',
  ST: 'Student'
}

const careerPathCdLabels = {
  GENMGMT: 'General Management',
  GOLFOP: 'Golf Operations',
  OWNLS: 'Ownership & Leasing',
  EXECMGMT: 'Executive Management',
  INSTRUCT: 'Teaching & Coaching',
  RETAIL: 'Retail',
  PLYRDEV: 'Player Development'
}

const programCdLabels = {
  SPP: 'Specialized Professional',
  CPP: 'Certified Professional',
  MPP: 'Master Professional'
}

const sortByKey = curry((key, items) => sortBy(prop(key))(items))

export const updateLabelAndSort = labelMap => items => {
  const sortOrder = Object.keys(labelMap)
  return items
    .sort((a, b) => sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label))
    .map(i => ({
      ...i,
      label: labelMap[i.label] || i.label
    }))
}

const showFilterIf = ({ visibleIfAttribute, searchState, ...options }) => {
  const isRefined = path(['refinementList', visibleIfAttribute], searchState)
  return Array.isArray(isRefined) ? [options] : []
}

export default searchState => [
  {
    label: 'Member Type',
    attributeName: 'member_type_label',
    initialOpen: true,
    transformItems: updateLabelAndSort(typeLabels)
  },
  {
    label: 'Member Certification',
    attributeName: 'programHistory.programCode',
    initialOpen: true,
    transformItems: updateLabelAndSort(programCdLabels)
  },
  ...showFilterIf({
    label: 'Career Path',
    attributeName: 'programHistory.careerPathCode',
    visibleIfAttribute: 'programHistory.programCode',
    initialOpen: true,
    transformItems: updateLabelAndSort(careerPathCdLabels),
    searchState
  }),
  {
    label: 'Facility Type',
    attributeName: 'facility_type_label',
    showMore: true,
    limitMin: 3,
    limitMax: 15,
    initialOpen: true,
    transformItems: sortByKey('label')
  },
  {
    label: 'Sections & PGM Universities',
    attributeName: 'section_name',
    showMore: true,
    limitMin: 5,
    limitMax: 100,
    initialOpen: false,
    transformItems: sortByKey('label')
  }
]
