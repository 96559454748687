import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ConfigureWithDefault from './ConfigureWithDefault'
import { MapMarker, LocationDropDown, MileSelect } from '../shared'
import { withSearchConsumer } from '../../SearchContext'
import PlacesLocationInput, {defaultPlacesConfig} from './PlacesLocationInput'

const proximityOptions = [
  { text: '15 miles', value: 15 },
  { text: '25 miles', value: 25 },
  { text: '50 miles', value: 50 },
  { text: '75 miles', value: 75 },
  { text: '100 miles', value: 100 }
]

export const milesToMeters = miles => miles * 1609
export const meterToMiles = meters => meters / 1609

export const PLACES_INPUT_ID = '#places-location-input'

export class PlacesInput extends React.Component {
  constructor (props) {
    super(props)
    this.onLocationChange = this.onLocationChange.bind(this)
    this.onRadiusChange = this.onRadiusChange.bind(this)
    this.onLocationClear = this.onLocationClear.bind(this)
  }

  onLocationChange ({ lat, lng, suggestionValue }) {
    const { onSearchStateChange } = this.props
    const options = {
      page: 1,
      configure: {
        aroundLatLng: `${lat}, ${lng}`,
        suggestionValue
      }
    }
    onSearchStateChange(options)
  }

  onLocationClear () {
    const { onSearchStateChange } = this.props
    const options = { page: 1, configure: { aroundLatLng: undefined, suggestionValue: undefined } }
    onSearchStateChange(options)
  }

  onRadiusChange ({ target: { value } }) {
    const { onSearchStateChange } = this.props
    const aroundRadius = milesToMeters(value)
    const options = { configure: { aroundRadius } }
    onSearchStateChange(options)
  }

  render () {
    const { aroundRadius, suggestionValue } = this.props
    return (
      <Fragment>
        <ConfigureWithDefault {...this.props} />
        <PlacesLocationInput
          {...defaultPlacesConfig}
          defaultValue={suggestionValue}
          onChange={this.onLocationChange}
          onClear={this.onLocationClear} />
        <LocationDropDown>
          <label htmlFor='location-proximity-miles'>
            <MapMarker />within
          </label>
          <MileSelect
            onChange={this.onRadiusChange}
            id='location-proximity-miles'
            value={meterToMiles(aroundRadius)}
          >
            {proximityOptions.map(({ text, value }) => (
              <option value={value} key={value}>
                {text}
              </option>
            ))}
          </MileSelect>
        </LocationDropDown>
      </Fragment>
    )
  }
}

PlacesInput.propTypes = {
  onSearchStateChange: PropTypes.func.isRequired,
  places: PropTypes.func.isRequired
}

export default withSearchConsumer(PlacesInput)
