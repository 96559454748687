import React from 'react'

const SearchContext = React.createContext()

export const withSearchConsumer = Component => props => (
  <SearchContext.Consumer>
    {searchConfig => {
      const injectProps = { ...props, ...searchConfig }
      return (
        <Component {...injectProps} />
      )
    }}
  </SearchContext.Consumer>
)

export default SearchContext
