import React, { Fragment } from 'react'
import styled, { withTheme } from 'styled-components'
import { UserIcon, LocationIcon } from '@pga/pga-component-library'

export const TextFrame = styled.p`
  font-size: 12px;
  line-height: 1rem;
  font-weight: 500;
  font-family: ${props => props.theme.fonts.Montserrat};
  margin-bottom: 0;
  margin-right: 15px;
  color: ${props => props.theme.colors.primary};
  @media screen and (max-width: 1020px) {
    font-weight: 500;
    font-size: 12px;
    margin-right: 0px;
    flex-grow: 1;
  }
  @media screen and (max-width: 575.98px) {
    flex-grow: 0;
  }
`

const Line = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 10px;
`

export const CardMemberInfoText = ({ hit }) => (
  <Fragment>
    <Line><UserIcon pr='5' pl='0' pb='0' /><TextFrame>{ hit.section_name || hit.member_class_description }</TextFrame></Line>
    <Line><LocationIcon pr='5' pl='0' pb='0' /><TextFrame>{ hit.city }</TextFrame></Line>
  </Fragment>
)

export default withTheme(CardMemberInfoText)
