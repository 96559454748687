import React from 'react'
import styled, { withTheme } from 'styled-components'

export const StyledText = styled.p`
  min-width: 650px;
  text-align: center;
  font-size: 32px;
  font-family: ${props => props.theme.fonts.PlayfairDisplay};
  font-weight: bold;
  color: ${props => props.theme.colors.primary}
`

export const NoResults = () => (
  <StyledText>No Results Found</StyledText>
)

export default withTheme(NoResults)
