import React, { Fragment } from 'react'
import styled, { withTheme } from 'styled-components'
import { connectStateResults } from 'react-instantsearch/connectors'
import { Hits } from 'react-instantsearch/dom'
import RenderResults from './RenderResults'
import NoResults from './NoResults'

export const makePaginationString = ({ nbHits, hitsPerPage, page }) => {
  let rangeEnd = hitsPerPage * (page + 1)
  const rangeStart = rangeEnd - hitsPerPage + 1

  if (nbHits && hitsPerPage) {
    if (rangeEnd >= nbHits) {
      rangeEnd = nbHits
    }
    return `Showing ${rangeStart} - ${rangeEnd} out of ${nbHits.toLocaleString()}`
  }
}

export const PaginationText = styled.p`
  text-align: center;
  font-size: 12px;
  font-family: ${props => props.theme.fonts.Montserrat};
  margin: 20px 0 0 0;
`

export const CardContainer = ({ searchState, searchResults }) => {
  const paginationInfo = searchResults ? makePaginationString(searchResults) : null
  return (searchResults && searchResults.nbHits !== 0)
    ? (
      <Fragment>
        <Hits hitComponent={RenderResults} />
        <PaginationText>{ paginationInfo }</PaginationText>
      </Fragment>
    )
    : <NoResults />
}

export default connectStateResults(withTheme(CardContainer))
