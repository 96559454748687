import React from 'react'
import styled from 'styled-components'
import { Title } from '@pga/pga-component-library'
import PlacesInput from './Places'
import { Separator } from './shared'
import FilterClear from './FilterClear'

const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`
export default (props) => (
  <LocationWrapper>
    <Title>Location</Title>
    <FilterClear {...props} />
    <PlacesInput {...props} />
    <Separator horizontal />
  </LocationWrapper>
)
