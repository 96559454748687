import qs from 'qs'

export const createURL = state => `?${qs.stringify(state)}`

export const searchStateToUrl = (props, searchState) =>
  searchState ? `${props.location.pathname}${createURL(searchState)}` : ''

export const urlToSearchState = location => qs.parse(location.search.slice(1))

export const DEFAULT_RADIUS = 24135 // algolia places initial search radius in meters (15 miles)

export const defaultConfigure = {
  hitsPerPage: 6,
  paginationPadding: 1,
  configure: {}
}
