const pgaTld = window.location.hostname.replace(/^[^.]+\.(sandbox)?pga.org/, '$1pga.org') || 'pga.org'

export const loggedInOptions = [
  {
    id: 1,
    to: `https://account.${pgaTld}`,
    label: 'My Account',
    external: true
  },
  {
    id: 2,
    to: `https://jobs.${pgaTld}`,
    label: 'Jobs',
    external: true
  },
  {
    id: 3,
    to: '/',
    label: 'Directory',
    root: true
  },
  {
    id: 4,
    to: `https://news.${pgaTld}`,
    label: 'News',
    external: true
  },
  {
    id: 5,
    to: `https://events.${pgaTld}`,
    label: 'Events',
    external: true
  },
  {
    id: 6,
    to: `https://resources.${pgaTld}`,
    label: 'Resources',
    external: true
  },
  {
    id: 7,
    to: `https://resources.${pgaTld}/my-membership/pga-frisco`,
    label: 'PGA Frisco',
    external: true
  }
]

export const loggedOutOptions = [
  {
    id: 1,
    to: `https://www.${pgaTld}`,
    label: 'Home',
    external: true
  },
  {
    id: 2,
    to: `https://jobs.${pgaTld}`,
    label: 'Jobs',
    external: true
  },
  {
    id: 3,
    to: '/',
    label: 'Directory',
    root: true
  },
  {
    id: 4,
    to: `https://news.${pgaTld}`,
    label: 'News',
    external: true
  },
  {
    id: 5,
    to: `https://events.${pgaTld}`,
    label: 'Events',
    external: true
  }
]

export const loggedInMobileOptions = [
  {
    id: 10,
    to: 'https://webmail.pgalinks.com/index.php',
    label: 'My Email',
    external: true,
    target: '_blank'
  },
  {
    id: 11,
    to: 'http://apps.pga.org/professionals/apps/membergolfshop/index.cfm?SMSESSION=NO',
    label: 'Shop',
    external: true,
    target: '_blank'
  },
  {
    id: 12,
    to: '/logout',
    label: 'Logout'
  }
]

export const loggedOutMobileOptions = [
  {
    id: 7,
    to: 'https://webmail.pgalinks.com/index.php',
    label: 'My Email',
    external: true,
    target: '_blank'
  },
  {
    id: 8,
    to: 'https://apps.pga.org/professionals/apps/membergolfshop/index.cfm?SMSESSION=NO',
    label: 'Shop',
    external: true,
    target: '_blank'
  },
  {
    id: 9,
    to: '/login',
    label: 'Login'
  },
  {
    id: 10,
    to: `https://account.${pgaTld}/signup`,
    label: 'Create Account',
    external: true
  }
]

export const dropdownLinks = [
  {
    to: 'https://webmail.pgalinks.com/index.php',
    label: 'My Email',
    external: true,
    target: '_blank'
  }
]
