import React, { Fragment } from 'react'
import styled, { withTheme } from 'styled-components'
import { SecondaryNav, IframeRolex } from '@pga/pga-component-library'
import options from './options'

export const NavFrame = styled.div`
  padding-bottom: 30px;
  z-index: 5;

  @media (max-width: 992px) {
    padding:0;
    margin:0;
  }
`

export const SubNavigation = ({ loading }) => <NavFrame>
  {!loading ? (
    <Fragment>
      <SecondaryNav options={options} title='Directory' menuTitle='Directory Menu' />
      <IframeRolex mobile />
    </Fragment>

  ) : null}
</NavFrame>

export default withTheme(SubNavigation)
export { options }
