import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { InstantSearch, Configure } from 'react-instantsearch/dom'
import Header from '../Header'
import Footer from '../Footer'
import theme from './theme'
import { SubNavigation } from '../SubNavigation'

const searchParameters = {
  hitsPerPage: 6
}

const appId = process.env.REACT_APP_ALGOLIA_APP_ID
const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY
const indexName = process.env.REACT_APP_ALGOLIA_INDEX_NAME

const ContentWrapper = styled.div`
  font-size: 12px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`

const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
`

export default ({ children }) => (
  <ThemeProvider theme={theme}>
    <ContentWrapper>
      <InstantSearch
        appId={appId}
        apiKey={apiKey}
        indexName={indexName}
      >
        <Configure {...searchParameters} />
        <Header theme={theme} />
        <CustomContainer>
          <SubNavigation />
          <section>
            {children}
          </section>
        </CustomContainer>
        <Footer />
      </InstantSearch>
    </ContentWrapper>
  </ThemeProvider>
)
