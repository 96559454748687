import ApolloClient from 'apollo-boost'
import { getToken, getImpersonationHeader } from '../lib/auth'

export const getAuthHeader = token => token && { Authorization: `Bearer ${token}` }

const apolloClient = new ApolloClient({
  uri: `${process.env.REACT_APP_V3_API_HOST}`,
  request: async operation => {
    try {
      const token = await getToken()
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          ...getAuthHeader(token),
          ...getImpersonationHeader()
        }
      }))
    } catch (e) {
      throw new Error(e)
    }
  },
  clientState: { }
})

export default apolloClient
