import styled from 'styled-components'
import MdClose from 'react-icons/lib/md/close'
import { Panel } from '@pga/pga-component-library'

export const Container = styled(Panel)`
  padding: 20px;
  min-width: 220px;
  max-width: 280px;
  @media screen and (max-width: 1020px) {
    width: 100%;
    max-width: 100%;
    ${props => !props.expanded && 'display: none;'}
    transition: display 1s;
  }
`

export const FilterResults = styled(Panel)`
  display: none;
  @media screen and (max-width: 1020px) {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    font-family: ${props => props.theme.fonts.Montserrat};
    cursor: pointer;
    ${props => props.expanded && 'display: none;'}
  }
`

export const HideFilters = styled.p`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  font-family: ${props => props.theme.fonts.Montserrat};
  cursor: pointer;
  color: ${props => props.theme.colors.lightBlue};
  width: 100%;
  @media screen and (min-width: 1020px) {
    display: none;
  }
`

export const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const CloseIcon = styled(MdClose)`
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
`
