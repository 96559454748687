import React, { Fragment } from 'react'
import { compose } from 'ramda'
import styled from 'styled-components'
import MediaQuery from 'react-responsive'
import { NavLink } from 'react-router-dom'
import { withAuthProvider } from '../../lib/auth'
import { Header, Nav, IconsBox, MobileNavigation, Theme, LoadingComponent, IframeRolex } from '@pga/pga-component-library'
import AuthHeader from '../AuthHeader'
import PGALogo from '../assets/logo.svg'
import { loggedInOptions, loggedOutOptions, loggedInMobileOptions, loggedOutMobileOptions, dropdownLinks } from './options'
import { withEmailMigrationData, withSetEmailMigrationMutation } from './query'

const NavigationBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`
const SideNavLink = styled(NavLink)`
  font-family: ${Theme.fonts.Montserrat};
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  position: relative;

  &.active {
    font-weight: 700;
  }

  &.active::before {
    content: '';
    position: absolute;
    vertical-align: middle;
    width: 100%;
    color: ${Theme.colors.gold};
    bottom: -10px;
    border-bottom: 4px solid;
  }

  :hover, &.active {
    text-decoration: none;
    color: #fff;
  }
`

export const HeaderComp = ({ isLoggedIn, theme, loading, me, authReady, emailMigrationData, emailMigrationMutation }) => {
  const options = isLoggedIn ? loggedInOptions : loggedOutOptions
  const mobileOptions = isLoggedIn ? options.concat(loggedInMobileOptions) : options.concat(loggedOutMobileOptions)
  const logoLink = isLoggedIn ? 'https://account.pga.org' : 'https://beta.pga.org'

  const { loading: isLoading, meEmailMigrationData: migrationData } = emailMigrationData || {}
  const meEmailMigrationData = migrationData || {}
  const { id, doNotShowAgain } = meEmailMigrationData

  const onToggleDoNotShowAgain = () => {
    emailMigrationMutation({ variables: { id, input: { doNotShowAgain: !doNotShowAgain } } })
  }

  if (!authReady || isLoading) {
    return <LoadingComponent />
  }
  return (
    <Fragment>
      <Header {...{ logo: PGALogo, bgColor: theme.colors.primary, logoLink: logoLink }}>
        <MediaQuery minWidth={992}>
          <NavigationBox>
            <Nav options={options} b={-31} l={0} />
            <div className='d-flex align-items-center'>
              <IframeRolex />
              <IconsBox {...{ isLoggedIn, meEmailMigrationData, onToggleDoNotShowAgain, me }} />
            </div>
            <AuthHeader {...{ loading, isLoggedIn, me, authReady, options: dropdownLinks, meEmailMigrationData, onToggleDoNotShowAgain }} />
          </NavigationBox>
        </MediaQuery>
        <MediaQuery maxWidth={991}>
          <MobileNavigation {...{ linkComponent: SideNavLink, options: mobileOptions, me, comp: SideNavLink, b: -7, l: 11, isLoggedIn, meEmailMigrationData, onToggleDoNotShowAgain }} vertical />
        </MediaQuery>
      </Header>
    </Fragment>
  )
}

export default compose(
  withEmailMigrationData,
  withSetEmailMigrationMutation,
  withAuthProvider
)(HeaderComp)
