import React from 'react'
import styled from 'styled-components'
import { RedirectToLogin, RedirectToLogout, ImpersonationOverlay } from '../lib/auth'
import { LoadingComponent, withPageMetadata, metadata } from '@pga/pga-component-library'
import { Route, Switch } from 'react-router-dom'
import '../App.css'
import Loadable from 'react-loadable'
import Main from './Directory/Main'
import Layout from './Layout'

const MainBox = styled.main`
  height: 80vh;
`

const Member = Loadable({ loader: () => import('./Member'), loading: LoadingComponent })
Member.displayName = 'AsyncMember'

const Facility = Loadable({ loader: () => import('./Facility'), loading: LoadingComponent })
Facility.displayName = 'AsyncFacility'

const MainWithMeta = withPageMetadata(metadata('Directory'))(Main)
export default () => (
  <MainBox>
    <ImpersonationOverlay />
    <Layout>
      <Switch>

        <Route exact path='/' component={MainWithMeta} />
        <Route
          path='/member/detail/:id'
          component={props => withPageMetadata(metadata('Member Profile'))(Member)({ ...props })}
        />

        <Route
          path='/facility/detail/:id'
          component={props => withPageMetadata(metadata('Facility Profile'))(Facility)({ ...props })}
        />

        <Route exact path='/login' component={() => <RedirectToLogin returnTo={`${window.location.origin}/`} />} />
        <Route exact path='/logout' component={() => <RedirectToLogout />} />
      </Switch>
    </Layout>
  </MainBox>
)
