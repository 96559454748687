import { Theme } from '@pga/pga-component-library'

export default {
  ...Theme,
  footerSectionHeader: 'white',
  footerSectionContent: 'gray',
  footerCopyright: '#999999',
  footerBackground: ' #00234b',
  primaryShadow: 'rgba(0, 35, 75, 0.15)',
  editBlue: '#007bff'
}
