import React from 'react'
import { Configure } from 'react-instantsearch/dom'
import { isNil } from 'ramda'

export const ConfigureWithLatLng = (config) => (
  <Configure
    aroundLatLng={config.aroundLatLng}
    aroundRadius={config.aroundRadius}
    hitsPerPage={config.hitsPerPage}
    facets={['_geoloc', 'zip']}
  />
)

export const ConfigureWithoutLatLng = (config) => (
  <Configure
    hitsPerPage={config.hitsPerPage}
    facets={['_geoloc', 'zip']}
  />
)

export default (props) => (
  isNil(props.aroundLatLng)
    ? <ConfigureWithoutLatLng {...props} />
    : <ConfigureWithLatLng {...props} />
)
