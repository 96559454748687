import React from 'react'
import styled from 'styled-components'
import defaultMemberImage from '../../assets/profileImageSquare.svg'
import defaultFacilityImage from '../../assets/defaultFacility.svg'

const ProfilePhoto = styled.img`
  background-color: #7d91a7;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100%;
  width: 150px;
  height: 150px;
  object-fit: contain;
  margin-right: 30px;
  @media screen and (max-width: 575.98px) {
    width: 75px;
    height: 75px;
    padding: 0px;
    margin-right: 10px;
  }
`

const PhotoFrame = styled.div`
  @media screen and (max-width: 1020px) {
    padding: 0;
  }
  @media screen and (max-width: 575.98px) {
    height: 75px;
  }
`

export const getDefaultImage = (classification, event) => {
  classification.name === 'facility'
    ? event.target.src = defaultFacilityImage
    : event.target.src = defaultMemberImage
}

export default ({ classification }) => (
  <PhotoFrame>
    <ProfilePhoto
      onError={(event) => getDefaultImage(classification, event)}
      src={classification.photoUrl}
      alt='Profile'
    />
  </PhotoFrame>
)
