import styled from 'styled-components'
import { Theme, LinkIcon, CloseIcon } from '@pga/pga-component-library'

import { FormGroup } from 'reactstrap'

export const LocationFilterContainer = styled.div`
  padding-bottom: 20px;
  .ap-icon-pin {
    display: none;
  }
  .ap-name {
    color: ${Theme.colors.primary};
  }
  #location-zipcode {
    ::placeholder {
    color: ${Theme.colors.blueGrey};
    font-family: ${Theme.fonts.Montserrat};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    }
  }
`

export const AngleDown = styled(LinkIcon)`
  display: none;
  font-size: 16px;
  transform: rotate(90deg);
  color: ${Theme.colors.primary};
  @media screen and (max-width: 991.98px) {
    display: block;
  }
  @media screen and (max-width: 575px) {
    margin-top: 5px;
  }
`

export const ClearWrapper = styled.div`
  display: flex;
  align-items: center;
  top: 0;
  right: 0;
  position: absolute;
  padding: 10px;
  height: 100%;
`

export const ClearIcon = styled(CloseIcon)`
  margin-top: -15px;
  padding: 5px;
`

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`

export const InputWrapper = styled(FormGroup)`
  min-width: 100%;
`

export const StyledInput = styled.input`
  .input {
    width: 100%;
  }
`
