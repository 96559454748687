import React from 'react'
import styled, { withTheme } from 'styled-components'
import { Link } from 'react-router-dom'
import { Highlight } from 'react-instantsearch/dom'
import { MemberTypeBadge } from '@pga/pga-component-library'
import CardPhoto from './CardPhoto'
import CardMemberInfoText from './CardMemberInfoText'

export const Wrapper = styled(Link)`
  height: 150px;
  transition: box-shadow 150ms;
  width: 100%;
  background-color: white;
  margin-bottom: 1rem;
  box-shadow: 0 5px 10px 0 ${props => props.theme.colors.primaryShadow};
  &:hover {
    text-decoration: none;
  }
  @media screen and (max-width: 575.98px) {
    height: auto;
  }
`

export const CardFrame = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 1rem;
  height: 75px;
  width: 100%;
  @media screen and (max-width: 575.98px) {
    height: auto;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    margin-top: 10px;
  }
`

const BadgeWrapper = styled.div`
  margin-top: 25px;
  width: 72px;
  & span {
    margin-left: 0px;
    box-shadow: 0 0 0 0;
  }
  @media screen and (max-width: 768px) {
    margin-top: 5px;
  }
`

export const SectionText = styled.span`
  color: ${props => props.theme.colors.primary};
  font-size: 24px;
  font-weight: bold;
  line-height: 1rem;
  font-family: ${props => props.theme.fonts.PlayfairDisplay};
  padding-top: 15px;
  @media screen and (max-width: 768px) {
    padding-top: 25px;
  }
  @media screen and (max-width: 575.98px) {
    font-size: 22px;
    line-height: 28px;
    padding-top: 10px;
  }
`

const TextWrapped = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 180px;
  padding-top: 25px;
  max-height: 75px;
  max-width: 100%;
  @media screen and (max-width: 575.98px) {
    flex-direction: column;
    padding: 10px;
    margin: 0;
  }
`

export const getPathName = name => (
  name === 'facility' ? 'facility' : 'member'
)

export const Cards = ({ hit, classification, facilityView }) => (
  <Wrapper to={`/${getPathName(classification.name)}/detail/${hit.objectID || hit.universal_id}`}>
    <CardFrame>
      <CardPhoto {...{ classification }} />
      <InfoWrapper>
        <BadgeWrapper>
          <MemberTypeBadge type={classification.type} />
        </BadgeWrapper>
        {
          facilityView
            ? <SectionText>{hit.profile_name}</SectionText>
            : classification.name !== 'facility'
              ? <SectionText><Highlight attributeName='profile_name' hit={hit} /></SectionText>
              : <SectionText><Highlight attributeName='name' hit={hit} /></SectionText>
        }
      </InfoWrapper>
    </CardFrame>
    <TextWrapped>
      <CardMemberInfoText {...{ hit, classification, facilityView }} />
    </TextWrapped>
  </Wrapper>
)

export default withTheme(Cards)
