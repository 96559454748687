import React, { useEffect, useRef, useState } from 'react'
import { Loader } from '@googlemaps/js-api-loader'
import PropTypes from 'prop-types'
import { InputContainer, ClearWrapper, ClearIcon, InputWrapper, StyledInput } from './styles'

import './google-places-override.css'

const apiLoader = new Loader({
  apiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&loading=async`,
  version: 'weekly',
  libraries: ['places']
})

const handleAPILoad = (onSearchStateChange, autoCompleteRef) => {
  apiLoader.load().then(() => {
    getApiLoader(autoCompleteRef, onSearchStateChange)
  })
}

export const getApiLoader = (autoCompleteRef, onSearchStateChange) => {
  const placesAutoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    {
      types: ['locality', 'postal_code'],
      componentRestrictions: { country: 'us' }
    }
  )
  placesAutoComplete.setFields(['geometry', 'name'])
  placesAutoComplete.addListener('place_changed', () =>
    handleSelection(onSearchStateChange, placesAutoComplete)
  )
}

export const handleSelection = (onSearchStateChange, placesAutoComplete) => {
  const place = placesAutoComplete.getPlace()
  const { geometry, name } = place

  if (place) {
    if (geometry) {
      const geo = place.geometry.location.toJSON()
      const { lat, lng } = geo
      onSearchStateChange({
        lat,
        lng,
        suggestionValue: name
      })
    }
  }
}

const PlacesLocationInput = ({ placeholder, id, onChange, defaultValue, onClear }) => {
  const [locationValue, setLocationValue] = useState('')
  const autoCompleteRef = useRef(null)

  const handleClear = () => {
    const locationInput = document.getElementById(elementId)
    if (locationInput) locationInput.value = ''
    setLocationValue(onClear)
  }

  useEffect(() => {
    const loadAPI = async () => {
      handleAPILoad(onChange, autoCompleteRef)
    }
    loadAPI()
  }, [])

  return (
    <InputContainer>
      <InputWrapper>
        <StyledInput
          type='text'
          className='form-control'
          placeholder={placeholder}
          id={id}
          onChange={(event) => setLocationValue(event.target.value)}
          ref={autoCompleteRef}
          defaultValue={defaultValue}
        />
        <ClearWrapper onClick={handleClear}>{locationValue && (<ClearIcon />)}</ClearWrapper>
      </InputWrapper>
    </InputContainer>
  )
}

PlacesLocationInput.propTypes = {
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func
}

const elementId = 'location-zipcode'

export const defaultPlacesConfig = {
  placeholder: 'City or Zip Code',
  id: elementId
}

export default PlacesLocationInput
