import styled from 'styled-components'
import { Col } from 'reactstrap'
import { Title } from '@pga/pga-component-library'

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  padding-bottom: 16px;

  > :before {
    content: '';
    opacity: 0.3;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 99%;
    z-index: 5;
    @media (min-width: 992px) {
      background-image: ${props => `linear-gradient(0deg, ${props.theme.primaryShadow}, ${props.theme.colors.primary})`};
    }
  }
`

export const Hero = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${props => props.theme.colors.primary};
  margin: -78px 0 0;
  height: 330px;
  background-image: url(${props => props.backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left bottom;

  @media (max-width: 991.99px) {
    margin: 0;
    width: 100%;
  }
`

export const HeroBody = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
`

export const Content = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const SearchTitle = styled(Title)`
  margin-bottom: 15px;
  @media (max-width: 767.99px) {
    font-size: 40px;
  }

  @media (max-width: 576.99px) {
    font-size: 28px;
    line-height: 0.95;
  }

  @media (max-width: 359.99px) {
    font-size: 28px;
    line-height: 0.95;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`

export const SearchInput = styled.input`
  height: 54px;
  padding-left: 55px;
  padding-right: 55px;
  border-radius: 0px;
  border: 0;
  outline: rgb(255, 255, 255) solid 1px;
  width: 650px;
  font-family: ${props => props.theme.fonts.Montserrat};
  font-weight: 300;
  font-size: 16px;
  &:focus {
    outline: none;
  }
  @media screen and (max-width: 1020px) {
    width: 500px;
  }
  @media screen and (max-width: 515px) {
    width: 250px;
  }
`
