import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connectStateResults } from 'react-instantsearch/connectors'
import { Theme } from '@pga/pga-component-library'
import { path, anyPass, isEmpty } from 'ramda'
import { PLACES_INPUT_ID } from './Places'

export const ClearButton = styled.button`
  border: none;
  background: none;
  color: ${Theme.colors.lightBlue};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 12px;
  padding: 0;
  text-align: left;
  margin: .5rem 0;
  opacity: ${props => props.disabled ? 0.6 : 1};
  cursor: ${props => !props.disabled && 'pointer'};
`

const handleClear = (onSearchStateChange) => {
  const locationInput = document.querySelector(PLACES_INPUT_ID)
  if (locationInput) locationInput.value = ''
  return onSearchStateChange({}, { clearAll: true })
}

export const FilterClear = (props) => {
  const { searchState, onSearchStateChange } = props
  const hasLocation = path(['configure', 'aroundLatLng'])
  const hasRefinements = path(['refinementList'])
  const hasQuery = path(['query'])

  const hasOneFilter = anyPass([hasLocation, hasRefinements, hasQuery])

  if (!isEmpty(searchState) && hasOneFilter(searchState)) {
    return (
      <ClearButton onClick={() => handleClear(onSearchStateChange)}>
        Clear All Filters
      </ClearButton>
    )
  }
  return <ClearButton disabled>Clear All Filters</ClearButton>
}

FilterClear.propTypes = {
  searchState: PropTypes.object,
  onSearchStateChange: PropTypes.func
}

export default connectStateResults(FilterClear)
