import React from 'react'

let AuthProvider,
  RedirectToLogin,
  RedirectToLogout,
  withAuthProvider,
  getToken

const { getImpersonationHeader, ImpersonationOverlay } = require('@pga/auth-flow-provider').impersonation

if (process.env.REACT_APP_AUTH_IMPL === 'auth0') {
  const AuthFlowProvider = require('@pga/auth-flow-provider').auth0.AuthFlowProvider
  const gql = require('apollo-boost').gql
  const GET_USER_DATA = gql`
    query User {
      me {
        id
        primaryEmail
        type
        firstName
        lastName
        photo
        class
        type
        displayName
        role
      }
    }
  `
  const fetchCurrentUser = async (token) => {
    try {
      const res = await fetch(process.env.REACT_APP_V3_API_HOST, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          ...getImpersonationHeader()
        },
        body: JSON.stringify({ query: GET_USER_DATA.loc.source.body })
      })

      const json = await res.json()
      const currentUser = json && json.data && json.data.me
      if (!currentUser) {
        throw new Error('No user found')
      }
      return [currentUser, null]
    } catch (err) {
      return [null, err]
    }
  }
  const { LoadingComponent } = require('@pga/pga-component-library')
  const renderRedirecting = () => (<LoadingComponent />)
  AuthProvider = ({ children }) => (
    <AuthFlowProvider fetchCurrentUser={fetchCurrentUser} renderRedirecting={renderRedirecting}>
      {children}
    </AuthFlowProvider>
  )

  RedirectToLogin = require('@pga/auth-flow-provider').auth0.RedirectToLogin
  RedirectToLogout = require('@pga/auth-flow-provider').auth0.RedirectToLogout
  withAuthProvider = require('@pga/auth-flow-provider').auth0.withAuthProvider
  getToken = require('@pga/auth-flow-provider').auth0.getToken
} else {
  const AuthFlowProvider = require('@pga/auth-flow-provider').onelogin.AuthFlowProvider
  const apolloClient = require('../ApolloClient').default
  AuthProvider = ({ children }) => (
    <AuthFlowProvider apolloClient={apolloClient} sessionCheckInterval={60000}>
      {children}
    </AuthFlowProvider>
  )

  RedirectToLogin = require('@pga/auth-flow-provider').onelogin.RedirectToLogin
  RedirectToLogout = require('@pga/auth-flow-provider').onelogin.RedirectToLogout
  withAuthProvider = require('@pga/auth-flow-provider').onelogin.withAuthProvider
  getToken = require('@pga/auth-flow-provider').onelogin.getToken
}

export {
  AuthProvider,
  RedirectToLogin,
  RedirectToLogout,
  withAuthProvider,
  getToken,
  getImpersonationHeader,
  ImpersonationOverlay
}
