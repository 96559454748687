import styled from 'styled-components'
import FaMapMarker from 'react-icons/lib/fa/map-marker'
import { Theme } from '@pga/pga-component-library'

export const Separator = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  ${props => (props.vertical && 'border-right') || (props.horizontal && 'border-bottom')}: 2px solid ${Theme.colors.darkgrey};
`
export const LocationDropDown = styled.div`
  font-family: ${Theme.fonts.Montserrat};
  font-size: 16px;
  &:focus {
    box-shadow: none;
    background-color: transparent;
  }
  @media screen and (max-width: 768px) {
    align-items: baseline;
  }
`

export const MileSelect = styled.select`
  margin-top: 10px;
  background-color: transparent;
  border: 0;
  font-size: 16px;
  height: 100%;
  font-family: ${Theme.fonts.Montserrat};
`

export const MapMarker = styled(FaMapMarker).attrs({
  size: '16px'
})`
  margin: 10px;
`

export const LocationInput = styled.input`
  font-family: ${Theme.fonts.Montserrat};
  font-weight: 400;
  font-size: 12px;
  margin-top: 15px;
  &:placeholder {
      color: ${props => props.theme.footerCopyright};
  }
  &:focus {
    outline: none;
  }
`
