import React from 'react'
import { compose } from 'react-apollo'
import { connectSearchBox } from 'react-instantsearch/connectors'
import { Container, Row } from 'reactstrap'
import styled, { withTheme } from 'styled-components'
import {
  HeroContainer,
  Hero,
  HeroBody,
  Content,
  SearchTitle,
  SearchInput,
  InputWrapper
} from './pieces'
import MdClear from 'react-icons/lib/md/clear'
import FaSearch from 'react-icons/lib/fa/search'

const ClearIcon = styled(MdClear)`
  position: absolute;
  top: 40%;
  right: 20px;
  cursor: pointer;
`

const SearchIcon = styled(FaSearch)`
  position: absolute;
  left: 20px;
  z-index: 1;
  color: rgb(153, 153, 153);
  margin-right: 15px;
`

export const Search = ({ currentRefinement, refine }) => (
  <HeroContainer>
    <Hero backgroundImg='https://res.cloudinary.com/pgahq/image/upload/v1554808672/front-end-assets/directory-search-hero.jpg'>
      <HeroBody>
        <Container>
          <Row>
            <Content>
              <SearchTitle size='lg'>Find Members or Facilities</SearchTitle>
              <InputWrapper>
                <SearchIcon size={'16px'} />
                <SearchInput
                  type='text'
                  value={currentRefinement}
                  onChange={e => refine(e.target.value)}
                  placeholder='Search Directory...'
                  autoFocus
                />
                {currentRefinement && <ClearIcon onClick={() => refine('')} />}
              </InputWrapper>
            </Content>
          </Row>
        </Container>
      </HeroBody>
    </Hero>
  </HeroContainer>
)

export default compose(
  withTheme,
  connectSearchBox
)(Search)
