import React from 'react'
import styled from 'styled-components'
import { Title } from '@pga/pga-component-library'
import { Separator } from './shared'

const Wrapper = styled.div`
  width: 100%;
  .ais-RefinementList__item .ais-RefinementList__itemBox {
    padding: 9px;
    width: 0px;
    height: 0px;
  }
  .ais-RefinementList__item input[type="checkbox"]:checked ~ .ais-RefinementList__itemBox {
    padding: 8px;
  }
`

export const RefineBy = ({ text, children }) => (
  <Wrapper>
    <Title>{ text }</Title>
    { children }
    <Separator horizontal />
  </Wrapper>
)

export default RefineBy
