import React from 'react'
import styled from 'styled-components'
import { InstantSearch, Pagination, Configure } from 'react-instantsearch/dom'
import { mergeDeepRight, pathOr, merge } from 'ramda'
import FaRight from 'react-icons/lib/fa/chevron-right'
import FaLeft from 'react-icons/lib/fa/chevron-left'

import { urlToSearchState, searchStateToUrl, defaultConfigure, DEFAULT_RADIUS, createURL } from './utils'
import SearchContext from './SearchContext'
import SearchBar from './SearchBar'
import Refiners from './RefineSearch/Refiners'
import CardContainer from './Cards/CardContainer'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row no-wrap;
  margin-top: 30px;
  padding-right: 100px;
  padding-left: 100px;
  @media screen and (max-width: 1020px) {
    flex-direction: column;
    padding: 10px;
  }
`

export const Main = styled.div`
  padding-left: 15px;
  padding-right: 10px;
  flex-grow: 0;
  max-width: 725px;
  min-width: 650px;
  @media screen and (max-width: 1020px) {
    max-width: 100%;
    padding: 0px;
    min-width: 100%;
  }
`

export const Sidebar = styled.div`
  padding-right: 15px;
  @media screen and (max-width: 1020px) {
    padding-right: 0px;
  }
`

const appId = process.env.REACT_APP_ALGOLIA_APP_ID
const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY
const indexName = process.env.REACT_APP_ALGOLIA_INDEX_NAME

export default class Layout extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      searchState: urlToSearchState(props.location)
    }
    this.onSearchStateChange = this.onSearchStateChange.bind(this)
    this.updateBrowserUrl = this.updateBrowserUrl.bind(this)
    this.resetBrowserUrl = this.resetBrowserUrl.bind(this)
  }

  componentWillReceiveProps (props) {
    if (props.location !== this.props.location) {
      this.setState({ searchState: urlToSearchState(props.location) })
    }
  }

  onSearchStateChange (searchState, { clearAll } = {}) {
    if (clearAll) {
      this.setState({ searchState: {} })
      return setTimeout(() => this.resetBrowserUrl(searchState), 300)
    }
    const nextState = mergeDeepRight(this.state.searchState, searchState)
    this.setState({ searchState: nextState })
    this.updateBrowserUrl(nextState)
  }

  updateBrowserUrl (searchState) {
    const updatedUrl = searchStateToUrl(this.props, searchState)
    this.props.history.push(
      updatedUrl,
      searchState
    )
  }

  resetBrowserUrl () {
    return this.props.history.push('/')
  }

  render () {
    const { onSearchStateChange } = this
    const { searchState } = this.state
    const { aroundLatLng, aroundRadius = DEFAULT_RADIUS } = pathOr({}, ['configure'], searchState)
    const contextValue = merge(defaultConfigure, { aroundLatLng, aroundRadius, onSearchStateChange })
    return (
      <InstantSearch
        appId={appId}
        apiKey={apiKey}
        indexName={indexName}
        searchState={this.state.searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <SearchContext.Provider value={contextValue}>
          <Configure filters={searchState.filters} />
          <SearchBar />
          <Wrapper>
            <Sidebar>
              <Refiners {...{ searchState, onSearchStateChange }} />
            </Sidebar>
            <Main>
              <CardContainer />
              <Pagination
                showFirst={false}
                pagesPadding={1}
                translations={{
                  next: <FaRight />,
                  previous: <FaLeft />
                }}
              />
            </Main>
          </Wrapper>
        </SearchContext.Provider>
      </InstantSearch>
    )
  }
}
